import React, { Component } from "react"
import styled from "@emotion/styled"

import { theme } from "../particles/theme"
import Layout from "./layout"
import SEO from "../seo"

const StyledMain = styled.div`
  margin-top: 10rem;

  @media (max-width: ${theme.breakpoints.tablet}) {
    margin-top: 5em;
  }

  table {
    width: 100%;
  }

  .buy {
  width: 100%;
  background: ${theme.colors.theHomeDepot.primary};
  display: inline-block;
  text-decoration: none;
  padding: 1rem 0;
  color: #fff;
  font-size: 1.8rem;
  text-align: center;
  cursor: pointer;
  box-sizing: content-box;
  border: 0;
  border-bottom: 3px solid ${theme.colors.theHomeDepot.hover};
  transition: 0.3s;
}

.reset {
  font-size 1.5em;
  margin-block-start .83em;
  margin-block-end .83em;
  font-weight: bold;
  text-transform: uppercase;
  color: ${theme.colors.theHomeDepot.primary};
  transition: outline .2s ease;
  cursor: pointer;
  text-decoration: none;
}

.reset:hover {
  color: ${theme.colors.theHomeDepot.hover};
}

.buy:hover {
  background: ${theme.colors.theHomeDepot.hover};
}

p {
  margin-block-start: .5em;
  margin-block-end: .5em;
}

.color {
  margin-top: 2em;
  margin-bottom: 2em;
  width: 100%;
  height: 160px;
  border: 1px solid #666;
}

`

class SelectProduct extends Component {
  constructor(props) {
    super(props)
    this.props = props
  }
  render() {
    let colors = this.props.pageContext.color;
    let idh = [];
    let idhmax = [];
    let quad_url = [];
    let quad_max_url = [];
    let quad_description = [];
    let quad_max_description = [];
    let unavailable = [];
    let delimiter = ', ';

    // make separate table rows for each idh/idhmax#
    if(colors.multivalIdh) {
      idh = colors.idh.split(delimiter);
      quad_url = colors.quad_url.split(delimiter);
      quad_description = colors.quad_description.split('; ');
    }
    else {
      idh.push(colors.idh);
      quad_url.push(colors.quad_url);
      quad_description.push(colors.quad_description);
    }

    if(colors.multivalIdhMax) {
      idhmax = colors.idhmax.split(delimiter);
      quad_max_url = colors.quad_max_url.split(delimiter);
      quad_max_description = colors.quad_max_description.split('; ');
      unavailable = [...colors.unavailable].map(Number);
    }
    else {
      idhmax.push(colors.idhmax)
      quad_max_url.push(colors.quad_max_url)
      quad_max_description.push(colors.quad_max_description)
      unavailable.push(parseInt(colors.unavailable))
    }
    let colorOptions = [];
    let tempColor = {};
    for (let i = 0; i < idh.length; i++) {
      if(idh[i]) {
        tempColor = {};
        tempColor.idh = idh[i];
        tempColor.idhmax = '';
        tempColor.link = quad_url[i];
        tempColor.description = quad_description[i];
        colorOptions.push(tempColor)
      }
    }
    for (let j = 0; j < idhmax.length; j++) {
      if(idhmax[j]) {
        tempColor = {};
        tempColor.idh = '';
        tempColor.idhmax = idhmax[j];
        tempColor.link = quad_max_url[j];
        tempColor.description = quad_max_description[j];
        tempColor.unavailable = unavailable[j];
        colorOptions.push(tempColor)
      }
    }

    var dedupIdh = this.props.pageContext.color.idh;
    if(dedupIdh) {
      dedupIdh = dedupIdh.toString().split(', ');
      dedupIdh = [...new Set(dedupIdh)];
      dedupIdh = dedupIdh.join(', ');
    }
    var dedupIdhmax = this.props.pageContext.color.idhmax;
    if(dedupIdhmax) {
      dedupIdhmax = dedupIdhmax.toString().split(', ');
      dedupIdhmax = [...new Set(dedupIdhmax)];
      dedupIdhmax = dedupIdhmax.join(', ');
    }

    return (
      <Layout>
        <SEO title="Colour Match Tool" />
        <StyledMain>
          <a className="reset" href="/">Reset Search</a>
          <h2>Current Display:</h2>
          <h3>LePage&reg; QUAD MAX / QUAD Colour Code {this.props.pageContext.color.osi}</h3>
          <p>LePage&reg; Colour Code: {this.props.pageContext.color.osi}</p>
          <p>QUAD MAX IDH#: {dedupIdhmax}</p>
          <p>QUAD IDH#: {dedupIdh}</p>
          <section className= "color" style={{background: `#${this.props.pageContext.color.colors.adjusted_rgb}`}}></section>
          <table>
            <tbody>
              <tr key='header'>
                <td style={{width: "10%"}}>
                  <p><strong>IDH/MAX#</strong></p>
                </td>
                <td style={{width: "45%"}}>
                  <p><strong>Description</strong></p>
                </td>
                <td style={{width: "20%"}}>
                </td>
              </tr>
              {colorOptions.map((color) => {
                let key = color.idh + ' ' +  color.idhmax + ' ' + color.link;
                return <tr key={key}>
                         <td><p>{color.idh}{color.idhmax}</p></td>
                         <td><p>{color.description}</p></td>
                         <td><p>{color.unavailable ? 'The QUAD MAX Product for this colour is temporarily unavailable.' : color.link ? <a className="buy" href={color.link}>Buy Now</a> : 'Contact your local Home Hardware for special orders.'}</p></td>
                       </tr>
              })}
            </tbody>
          </table>
        </StyledMain>
      </Layout>
    )
  }
}

export default SelectProduct
